// CustomDropdown.tsx
import React, { useState, useEffect } from 'react';

export type DurationType = 'Monthly' | 'Yearly';

interface CustomDropdownProps {
  currentDuration: DurationType;
  onChange: (newDuration: DurationType) => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({ currentDuration, onChange }) => {
  const [localDuration, setLocalDuration] = useState<DurationType>(currentDuration);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Whenever the local value changes, notify the parent.
  useEffect(() => {
    onChange(localDuration);
  }, [localDuration, onChange]);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  return (
    <div className="dropdown">
      <button
        className="rounded-pill px-3 py-1 subscription__header--dropdown"
        type="button"
        onClick={toggleDropdown}
        aria-expanded={isOpen}
      >
        {localDuration}
      </button>
      {isOpen && (
        <ul className="dropdown-menu show">
          <li>
            <button
              className="dropdown-item"
              onClick={(event) => {
                event.stopPropagation();
                console.log("Local duration changed to Monthly");
                setLocalDuration('Monthly');
                setIsOpen(false);
              }}
            >
              Monthly
            </button>
          </li>
          <li>
            <button
              className="dropdown-item"
              onClick={(event) => {
                event.stopPropagation();
                console.log("Local duration changed to Yearly");
                setLocalDuration('Yearly');
                setIsOpen(false);
              }}
            >
              Yearly
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
