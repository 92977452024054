import React from 'react';
import OrderSummary from './orderSummary';
import { AddOnCard } from './addOnCard';
import PaymentSection from './paymentSectionButtons';


interface MobileSubscriptionProps {
  addOns: any[];
  selectedAddOns: any[];
  handleSelect: (addOn: any) => void;
  handleRemove: (addOn: any) => void;
  displayAddOnsOrSummary: boolean;
  toggleDisplayAddOnsOrSummary: () => void;
  subtotal: number;
  discount: number;
  total: number;
  duration: "Yearly" | "Monthly";
  isOpen: boolean;
  dropdownRef: React.RefObject<any>;
  openAddMoneyModal: () => void;
}

const MobileSubscription: React.FC<MobileSubscriptionProps> = ({
  addOns,
  selectedAddOns,
  handleSelect,
  handleRemove,
  displayAddOnsOrSummary,
  toggleDisplayAddOnsOrSummary,
  subtotal,
  discount,
  total,
  duration,
  isOpen,
  dropdownRef,
  openAddMoneyModal,
}) => {
  return (
    <div className="row d-md-none d-block">
      {displayAddOnsOrSummary ? (
        <div className="col-sm-12 col-md-6">
          <OrderSummary
            selectedAddOns={selectedAddOns}
            handleRemove={handleRemove}
            subtotal={subtotal}
            discount={discount}
            total={total}
            duration={duration}
            isOpen={isOpen}
            dropdownRef={dropdownRef}
          />
          <PaymentSection onProceed={openAddMoneyModal} onToggle={toggleDisplayAddOnsOrSummary} />
        </div>
      ) : (
        <div className="col-sm-12 col-md-6">
          <button
            style={{
              color: '#2C4A8B',
              fontWeight: 500,
              fontSize: '.875rem',
              backgroundColor: 'transparent',
              border: 'none',
              marginTop: 24,
              marginBottom: 37,
            }}
            onClick={toggleDisplayAddOnsOrSummary}
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 5.5L5 15.5M5 5.5L15 15.5"
                stroke="#2C4A8B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>{' '}
            Cancel
          </button>
          <div className="subscription__addExtras">
            <h3 className="subscription__addExtras--title">Add Extras</h3>
            <p className="subscription__addExtras--description">
              Enhance your plan with additional features to propel your company forward.
            </p>
          </div>
          <div className="grid gap-4">
            {addOns.map((addOn) => (
              <AddOnCard
                key={addOn.id}
                addOn={addOn}
                selected={selectedAddOns.find((item) => item.id === addOn.id) || null}
                onSelect={handleSelect}
              />
            ))}
          </div>
          <PaymentSection onProceed={openAddMoneyModal} onToggle={toggleDisplayAddOnsOrSummary} />
        </div>
      )}
    </div>
  );
};

export default MobileSubscription;
