import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
// import HomePage from './views/home/components/HomePage'
import NotFoundPage from './views/notfound/components/NotFoundPage'
import { ROUTES } from './resources/routes-constants'
import './styles/main.sass'
import DownloadApp from './views/downloadPage'
import Header from './components/header'
import Footer from './components/footer'
import Products from './views/newProduct'
import ContactUs from './views/contactUs'
import DownloadAppVersion from './views/downloadVersion'
import PlansAndBills from './views/Dashboard/components/plans&billings'
import UserManagement from './views/Dashboard/components/user-management'
import SalesAndReport from './views/Dashboard/components/sales&report'
import Transaction from './views/Dashboard/components/transaction'

import Pricing from './views/pricing'
import PrivacyPolicy from './views/privacyPolicy'
import TermsAndConditions from './views/termsAndConditions'
import PlanDetailsPage from './views/plans-details'
import Locations from './views/location'
import { ProtectedRoute } from './resources/protectedRoute'
import Wallet from './views/wallet'
import SignIn from './views/authentication_2.0/signin'
import Signup from './views/authentication_2.0/signup'
import VerifyEmail from './views/authentication_2.0/verifyEmail'
import ForgotPassword from './views/authentication_2.0/forgotPassword'
import Opt from './views/authentication_2.0/optForForgetPassword'
import ResetPassword from './views/authentication_2.0/resetPassword'
import SetNewPassword from './views/authentication_2.0/setNewPassword'
import HomePage from './views/home_2.0'
import RegistrantTable from './views/registrant'
import AgentPlandetails from './views/newPlansDetails'
// import SignIn from './views/authentication/signin'
// import Signup from './views/authentication/signup'
// import VerifyEmail from './views/authentication/verifyEmail'
// import ForgotPassword from './views/authentication/forgotPassword'
// import Opt from './views/authentication/optForForgetPassword'
// import ResetPassword from './views/authentication/resetPassword'
// import SetNewPassword from './views/authentication/setNewPassword'


const RootComponent: React.FC = () => {
    const location = useLocation()

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }, 0) // Adjust delay as necessary, but often even 0 is enough
    }, [location])
    const isPlanDetailsPage = location.pathname.startsWith('/plans-details/') && location.pathname.split('/').length === 4
    return (
        <>
            {location.pathname !== '/login' &&
                location.pathname !== '/signup' &&
                location.pathname !== '/verify-email' &&
                location.pathname !== '/verify-phone' &&
                location.pathname !== '/forgot-password' &&
                location.pathname !== '/reset-password' &&
                location.pathname !== '/forgot-opt-reset' &&
                location.pathname !== '/set-newpassword' &&
                location.pathname !== '/sales-report' &&
                location.pathname !== '/dashboard' &&
                location.pathname !== '/user-management' &&
                location.pathname !== '/transactions' &&
                location.pathname !== '/plans-summary' &&
                location.pathname !== '/plans-and-billings' &&
                location.pathname !== '/privacy-policy' &&
                location.pathname !== '/plandetails' &&
                !isPlanDetailsPage &&
                location.pathname !== '/terms-and-conditions' &&
                location.pathname !== '/locations' && <Header />
                }

            <Routes>
                <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} />
                <Route path="/plans-details/plan_id=:plan_id&plan_duration=:plan_duration" element={<PlanDetailsPage />} />
                <Route path="/download" element={<DownloadApp />} />
                <Route path="/plans-details/:plan_id/:plan_duration" element={<PlanDetailsPage />} />
                <Route path="/products" element={<Products />} />
                <Route
                    path="/pricing"
                    element={
                        <>
                            <Pricing />
                        </>
                    }
                />
                    <Route path="/plandetails" element={<AgentPlandetails />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/registrant" element={<RegistrantTable />} />
                <Route path="/login" element={<SignIn />} />
                <Route element={<ProtectedRoute />}>
                    <Route
                        path="/transactions"
                        element={
                            <>
                                <Transaction />
                            </>
                        }
                    />
                    <Route path="/dashboard" element={<></>} />
                    <Route
                        path="/plans-and-billings"
                        element={
                            <>
                                <PlansAndBills />
                            </>
                        }
                    />
                    <Route
                        path="/sales-report"
                        element={
                            <>
                                <SalesAndReport />
                            </>
                        }
                    />
                    <Route
                        path="/user-management"
                        element={
                            <>
                                <UserManagement />
                            </>
                        }
                    />
                    <Route
                        path="/wallet"
                        element={
                            <>
                                <Wallet />
                            </>
                        }
                    />
                </Route>
                <Route path="/signup" element={<Signup />} />
                <Route path="/verify-email" element={<></>} />
                <Route path="/verify-phone" element={<VerifyEmail />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/forgot-opt-reset" element={<Opt />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/set-newpassword" element={<SetNewPassword />} />
                <Route path="/download/:platform" element={<DownloadAppVersion />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/locations" element={<Locations />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
            {location.pathname !== '/login' &&
                location.pathname !== '/signup' &&
                location.pathname !== '/verify-email' &&
                location.pathname !== '/verify-phone' &&
                location.pathname !== '/forgot-password' &&
                location.pathname !== '/reset-password' &&
                location.pathname !== '/set-newpassword' &&
                location.pathname !== '/sales-report' &&
                location.pathname !== '/dashboard' &&
                location.pathname !== '/user-management' &&
                location.pathname !== '/transactions' &&
                location.pathname !== '/payment-summary' &&
                location.pathname !== '/forgot-opt-reset' &&
                !isPlanDetailsPage &&
                location.pathname !== '/plans-and-billings' &&
                location.pathname !== '/plandetails' &&
                location.pathname !== '/locations' && <Footer />}
        </>
    )
}

export default RootComponent
