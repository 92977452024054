import React, { useEffect } from 'react';
import { SelectedAddOn } from '../../utility/subscriptionInterfaces';
import { Link } from 'react-router-dom';
import usePlanDetails from './hook/usePlanDetails';
import CustomDropdown from './customDropdown';

interface OrderSummaryProps {
    selectedAddOns: SelectedAddOn[];
    handleRemove: (id: string) => void;
    subtotal: number;
    discount: number;
    total: number;
    duration: 'Yearly' | 'Monthly';
    isOpen: boolean;
    // toggleDropdown: () => void;
    // handleDurationDropdown: (period: 'Yearly' | 'Monthly') => void;
    dropdownRef: React.RefObject<HTMLDivElement>;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({ selectedAddOns, handleRemove, subtotal, discount, total }) => {
    const { planDetails, handleDurationDropdown, duration, subtotal:sub, total: tot } = usePlanDetails();

    return (
        // <div className="col-sm-12 col-md-6">
        <>
            <Link className="mt-4" to="/ ">
                <img src="/assets/klakpad.svg" className="img-fluid mb-5 pb-5" width={122} alt="" />
            </Link>
            <div className="d-flex subscription__header align-items-center">
                <div className="d">
                    <span className="subscription__header--1">Upgrade to</span>
                    <span className="subscription__header--2">Plus Plan</span>
                </div>
                <CustomDropdown
                    currentDuration={duration}
                    onChange={newDuration => {
                        console.log('Propagating duration change:', newDuration);
                        handleDurationDropdown(newDuration);
                    }}
                />
            </div>
            <div className="d-flex subscription__profile">
                <div className="subscription__profile--title">
                    <div className="mt-1">A</div>
                </div>
                <div className="">
                    <div className="subscription__profile--name">Agent</div>
                    <div className="subscription__profile--email">emailaddress@gmail.com</div>
                </div>
            </div>
            <div className="d-flex subscription__plansDetails flex-column">
                <div className="">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17.5013 13.333V5.99967C17.5013 5.06625 17.5013 4.59954 17.3196 4.24302C17.1599 3.92942 16.9049 3.67445 16.5913 3.51466C16.2348 3.33301 15.7681 3.33301 14.8346 3.33301H5.16797C4.23455 3.33301 3.76784 3.33301 3.41132 3.51466C3.09771 3.67445 2.84275 3.92942 2.68296 4.24302C2.5013 4.59954 2.5013 5.06625 2.5013 5.99967V13.333M3.89019 16.6663H16.1124C16.6291 16.6663 16.8874 16.6663 17.0993 16.6096C17.6745 16.4554 18.1237 16.0062 18.2778 15.431C18.3346 15.2191 18.3346 14.9608 18.3346 14.4441C18.3346 14.1858 18.3346 14.0566 18.3062 13.9507C18.2292 13.6631 18.0046 13.4385 17.717 13.3614C17.611 13.333 17.4819 13.333 17.2235 13.333H2.77908C2.52075 13.333 2.39159 13.333 2.28562 13.3614C1.99804 13.4385 1.77342 13.6631 1.69636 13.9507C1.66797 14.0566 1.66797 14.1858 1.66797 14.4441C1.66797 14.9608 1.66797 15.2191 1.72476 15.431C1.87887 16.0062 2.32812 16.4554 2.90327 16.6096C3.11521 16.6663 3.37354 16.6663 3.89019 16.6663Z"
                            stroke="#394049"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <span className="ms-1 subscription__plansDetails--text">Klakpad Sella</span>
                </div>
                <div className="">
                    <span className="subscription__plansDetails--price">₦{duration === 'Yearly' ? planDetails[0]?.totalAmount.toLocaleString() : planDetails[0]?.amountMonth.toLocaleString()}</span>
                    <span className="subscription__plansDetails--period">/year for this branch</span>
                </div>
            </div>

            <div className="subscription__card card border-0">
                <div className="card-header bg-transparent subscription__card--header py-3">Order Summary</div>
                <div className="card-body">
                    <div className="d-flex justify-content-between subscription__card--content">
                        <span className="subscription__card--item">Standard Plan</span>
                        <span className="subscription__card--amount">₦{duration === 'Yearly' ? planDetails[0]?.totalAmount.toLocaleString() : planDetails[0]?.amountMonth.toLocaleString()}.00</span>
                    </div>
                    {duration === 'Yearly' && selectedAddOns.map((addOn, index) => (
                        <div key={index} className="d-flex justify-content-between mt-1 subscription__card--content">
                            <span>
                                {addOn.qty} {addOn.name}
                            </span>
                            <div className="">
                                <span className="subscription__card--amount">₦{addOn.price.toLocaleString()}</span>
                                <button onClick={() => handleRemove(addOn.id)} className="ms-1 bg-transparent border-0">
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="1" width="15" height="15" rx="7.5" fill="white" />
                                        <rect x="0.5" y="1" width="15" height="15" rx="7.5" stroke="#2C4A8B" />
                                        <path d="M4.5 8.5H11.5" stroke="#2C4A8B" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    ))}
                    {/* {selectedAddOns.length !== 0 && ( */}
                        <div className="border-top p-0 m-0">
                            <div className="d-flex subscription__card--subsection justify-content-between subscription__card--content">
                                <span className="subscription__card--item">Subtotal</span>
                               {/* { duration === 'Yearly' ? planDetails[0]?.totalAmount.toLocaleString() : planDetails[0]?.amountMonth.toLocaleString()} */}
                                <span className="subscription__card--amount">₦{ duration === 'Yearly' ?subtotal.toLocaleString() :sub.toLocaleString()}</span>
                                {/* <span className="subscription__card--amount">₦{subtotal.toLocaleString()}</span> */}
                            </div>
                            <div className="d-flex subscription__card--subsection justify-content-between">
                                <span className="subscription__card--item">Discount ({planDetails[0]?.resellerDiscount}%)</span>
                                <span className="subscription__card--amount">₦{duration === 'Yearly' ? discount.toLocaleString(): 0}</span>
                            </div>
                        </div>
                    {/* )} */}
                </div>
                <div className="card-footer bg-transparent subscription__card--footer py-4">
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <span>Total</span>
                        </div>
                        <div className="">
                            <span className="subscription__card--equivalent">{ duration === 'Yearly' ?subtotal.toLocaleString() : tot.toLocaleString()} KPT ≈ </span>
                            <span className="subscription__card--total">₦{ duration === 'Yearly' ?subtotal.toLocaleString() : tot.toLocaleString()}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderSummary;
