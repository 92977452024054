import React, { useEffect, useState } from 'react'
import CardOptions from './cardOptions'
import { FaArrowRight } from 'react-icons/fa6'
import PlanpaymentType from './paymentType'
import { CiCircleMinus, CiLaptop } from 'react-icons/ci'
import paymentTypes from '../../resources/paymentMethod.json'
import PayWithWallet from './payWithWallet'
import { planDetailsStates } from './planDetailsStates'
import { useDispatch } from 'react-redux'
import { updatePlanDetailsStates } from '../../store/actions/planDetails'
import { PaystackButton } from 'react-paystack'
import { PostRequest } from '../../resources/api-request'
import { convertToCamelCase, format } from '../../utility/functions'
import { LiaTimesSolid } from 'react-icons/lia'
import { Link, useNavigate } from 'react-router-dom'
import PlanDetailsMobile from './plansDetailsMobile'
import { calculateCharges } from './calculatePaystackCharges'
import { connect } from 'http2'

const SummaryForm = ({
    formattedAmount,
    // plan_id,
    plan_duration,
    orderSummary,
    handleDeleteOrderSummary,
    handleCoupon,
    discount,
    displaySubTotal,
    displayTotal,
    paymentGateway,
    applyCoupon,
    handlePaymentType,
    handlePayment,
    paymentMethod,
    paymentMethodChoices,
    handleRadioChange,
    planDetails,
    subscriptionData,
    handleChange
}: any) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    // const [isOnboarding, setOnboarding] = useState("self");
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {
        subAmount = 0,
        ordersSummary,
        finalTotal = 0,
        couponDiscount = 0,
        plan_id,
        duration,
        payment_method,
        coupon,
        card_type,
        saved_card,
        email,
        location_id,
        auth_code,
        validation,
        finalTotals = 0,
        additionalUsers = 0,
        additionalOutlets = 0,
        additionalProducts = 0,
        isOnboarding,
        self = 0,
        agent = 0,
        onboarding = 0,
        onboardingData,
        planPrice = 0,
    } = planDetailsStates()

    let fT = finalTotal
    let additionalU = 0
    let additionalO = 0
    let additionalP = 0
  
    if (ordersSummary && ordersSummary.length > 0) {
        const orderAmount = ordersSummary.reduce((acc: number, item: any) => {
            return acc + item.price
        }, 0);

        fT = orderAmount + finalTotal;
        const subAmount = planPrice + orderAmount +agent;

        console.log(planPrice, orderAmount, subAmount, "new");

        ordersSummary.map((item: any) => {
            if (item.name == 'Additional Active Users') {
                additionalU = item.qty
            } else if (item.name == 'Additional Unique Outlets') {
                additionalO = item.qty
            } else if (item.name == 'Additional Unique Products') {
                additionalP = item.qty
            }
        });

        dispatch(updatePlanDetailsStates({ finalTotals: fT, subAmount, additionalUsers: additionalU, additionalOutlets: additionalO, additionalProducts: additionalP }));
    } else {
        const subAmount = planPrice + 0 + agent;
        dispatch(updatePlanDetailsStates({ subAmount }));
    }

    const agentOnbaording = (type: string, amount: number, id: any) => {

        if ((type == 'Self Onboarding')) {
            if (agent > 0) {
                dispatch(updatePlanDetailsStates({
                    isOnboarding: type,
                    finalTotals: finalTotals - agent,
                    finalTotal: finalTotal - agent,
                    subAmount: Number(subAmount) - agent,
                    onboarding: id,
                    agent: 0
                }));
            } else {
                dispatch(updatePlanDetailsStates({
                    isOnboarding: type,
                    onboarding: id,
                }));
            }
        } else {
            if (agent == 0) {
                const total = finalTotal + amount;
                const totals = finalTotals + amount;

                dispatch(updatePlanDetailsStates({
                    isOnboarding: type,
                    finalTotals: totals,
                    finalTotal: total,
                    subAmount: Number(subAmount) + amount,
                    onboarding: id,
                    agent: amount
                }));
            }
        }
    }

    const amountNeeded = plan_duration === 'yearly' ? planDetails?.amountYear : planDetails?.amountMonth

    const componentProps = {
        email,
        amount: (finalTotals + calculateCharges(finalTotals)) * 100,
        // metadata: {
        //     name: 'Customer Name',
        //     phone: '080XXXXXXX',
        // },
        // publicKey: 'pk_live_56435f881fda43bb730858fd456f3f39931e8ee3',
        publicKey: 'pk_test_c366d758f39e4aaad479f672946348b5d12a5fde',
        text: `Proceed to payment`,
        onSuccess: (reference: any) => {
            subscribeWithCard(reference.reference)
            dispatch(updatePlanDetailsStates({ validation: false }))
        },
        onClose: () => console.log('Payment canceled')
    }

    const validateCardPayment = async () => {

        dispatch(updatePlanDetailsStates({ loader: true }));

        const response = await PostRequest('/validate-card-payment', {
            plan_amount: amountNeeded,
            total_amount: finalTotals + couponDiscount,
            discount_percentage: couponDiscount,
            amount_paid: finalTotals,
            payment_method,
            plan_id,
            card_type,
            coupon,
            duration,
            location_id,
            additional_products: additionalProducts,
            additional_users: additionalUsers,
            additional_warehouse: additionalOutlets,
            onboarding
        });

        if (response.data.error === false) {
            alert("You can now proceed to payment");
            dispatch(updatePlanDetailsStates({ validation: true, loader: false, isOnboarding: isOnboarding == "Self Onboarding" ? "Self Onboarding" : "Agent Assisted Onboarding" }));

        } else {
            alert(response.data.message);
            // console.log(response);
            dispatch(updatePlanDetailsStates({ loader: false, isOnboarding: isOnboarding == "Self Onboarding" ? "Self Onboarding" : "Agent Assisted Onboarding" }));
        }

    }

    const subscribeWithCard = async (reference: string) => {

        dispatch(updatePlanDetailsStates({ loader: true }));
        const response = await PostRequest('/subscribe-with-card', {
            plan_amount: amountNeeded,
            total_amount: finalTotals + couponDiscount,
            discount_percentage: couponDiscount,
            amount_paid: finalTotals,
            payment_method,
            plan_id,
            card_type,
            save_card: saved_card,
            email,
            reference_id: reference,
            coupon,
            duration,
            location_id,
            additional_products: additionalProducts,
            additional_users: additionalUsers,
            additional_warehouse: additionalOutlets,
            auth_code,
            onboarding
        });

        if (response.data.success === true) {
            dispatch(updatePlanDetailsStates({
                subAmount: finalTotal,
                ordersSummary: [],
                couponDiscount: 0,
                plan_id: '',
                duration: '',
                payment_method: '',
                coupon: '',
                card_type: '',
                saved_card: 0,
                email,
                location_id,
                auth_code: '',
                validation: false,
                finalTotals: finalTotal,
                additionalUsers: 0,
                additionalOutlets: 0,
                additionalProducts: 0
            }));
        }

        navigate('/download');

        console.log(response.data.message);
    }

    const subscribeFree = async () => {
        if (isOnboarding === '') {
            alert("You have to pick an onboarding type");
            return false;
        }
        dispatch(updatePlanDetailsStates({ loader: true }));
        const response = await PostRequest('/subscribe-for-free', {
            plan_amount: 0,
            total_amount: 0,
            discount_percentage: 0,
            amount_paid: 0,
            payment_method: 'free',
            plan_id: 2,
            card_type: '',
            save_card: 0,
            email,
            reference_id: 'free subscription',
            coupon: "",
            duration: 12,
            location_id,
            additional_products: additionalProducts,
            additional_users: additionalUsers,
            additional_warehouse: additionalOutlets,
            auth_code: '',
            onboarding
        });

        if (response.data.success === true) {
            alert(response.data.message);

            dispatch(updatePlanDetailsStates({
                subAmount: finalTotal,
                ordersSummary: [],
                couponDiscount: 0,
                plan_id: '',
                duration: '',
                payment_method: 'free',
                coupon: '',
                card_type: '',
                saved_card: 0,
                email,
                location_id,
                auth_code: '',
                validation: false,
                finalTotals: finalTotal,
                additionalUsers: 0,
                additionalOutlets: 0,
                additionalProducts: 0,
                loader: false
            }));
            navigate('/download')
        }


    }

    // Function to handle click event
    const handlePaymentProceed = () => {
        if (paymentMethod === 'wallet') {
            // Logic to trigger modal
            setIsModalVisible(true)
            console.log('Payment method is wallet')
        } else if (paymentMethod === 'card') {
            // Logic to proceed with calling an API
        } else {
            // Optionally, handle the case where no payment method is selected
            console.log('No payment method selected.')
        }
    }

    const registeredBranch = true;

    return (
        <>
            <PlanDetailsMobile
                validateCardPayment={validateCardPayment}
                formattedAmount={formattedAmount}
                subscriptionData={subscriptionData}
                handleChange={handleChange}
                applyCoupon={applyCoupon}
                plan_duration={plan_duration}
                handleDeleteOrderSummary={handleDeleteOrderSummary}
                handleCoupon={handleCoupon}
                handlePayment={handlePayment}
                paymentMethod={paymentMethod}
                paymentMethodChoices={paymentMethodChoices}
                handleRadioChange={handleRadioChange}
                componentProps={componentProps}
                planDetails={planDetails}
                agentOnbaording={agentOnbaording}
                subscribeFree={subscribeFree}
            />
            <div className="col-md-6  border-end border-1 pe-5 d-none d-md-block pb-5">
                <div className="d-flex justify-content-between mt-4">
                    <div>
                        {' '}
                        <Link className="mt-4" to="/ ">
                            <img src="/assets/klakpad.svg" className="img-fluid mb-5 pb-5" width={122} alt="" />
                        </Link>
                    </div>
                    {/* <div>
                        <button className="summary--exit" onClick={() => navigate('/pricing')}>
                            <LiaTimesSolid className='me-1'/> Exit
                        </button>
                    </div> */}
                </div>
                <div className="d-flex align-items-center flex-row mb-5">
                    <div className="pe-3" style={{letterSpacing: -0.625}}> <span className='' style={{color: '#888C91', fontWeight: 500, fontSize: 25}}>You’re about to subscribe to</span> <span style={{fontWeight: 600, fontSize: 25, color: '#394049'}}>{plan_id == '2' ? 'Plus' : 'Basic'} Plan</span></div>
                    <div className=" rounded-pill summary--badge">{convertToCamelCase(plan_duration)}</div>
                </div>

                <div className="d-flex align-items-center">
                    <span>
                        {' '}
                        <CiLaptop fontSize={24} className="mx-1" />
                    </span>
                    <span className="mt-1 summary__card--text2">Klakpad Retail</span>
                </div>
                <div className="mb-4 pb-3 pt-2">
                    <span className="summary__card--amount">₦{formattedAmount}</span>
                    {plan_duration == 'yearly' ? <span>{'/year'}</span> : <span>/monthly</span>}
                    {/* {plan_duration == 'yearly' ? <span>{registeredBranch ? '/year for this branch only' : '/year'}</span> : <span>/monthly</span>} */}
                </div>
                <div className="card summary__card mb-5">
                    <div className="card-header border-1 p-2  bg-transparent">
                        <h5 className="summary__card--subtitle pt-2 ps-3">Order Summary</h5>
                    </div>

                    <div className="card-body">
                        <div className="d-flex py-2 pe-4 pb-3 ps-2 ">
                            <div className="flex-grow-1">
                                <span className="summary__card--text2 ">{plan_id == '2' ? 'Plus Plan' : 'Enterprise Plan'}</span>
                            </div>
                            <div className=" text-end summary__card--text ">
                                <div className="d-flex align-items-center">
                                    <span className=" ">₦{formattedAmount}</span>
                                </div>
                            </div>
                        </div>
                        {isOnboarding && isOnboarding !== '' &&
                            (<div className="d-flex align-items-center pt-2 pe-3 pb-3 ps-2 " >
                                <div className="flex-grow-1">

                                    <span className="summary__card--text2"> {isOnboarding}</span>

                                </div>
                                <div className=" text-end summary__card--text ">
                                    <div className="d-flex align-items-center">
                                        <span className="mt-1"> ₦{new Intl.NumberFormat().format(agent)}</span>
                                        <span className="mx-1" />
                                        <span className="">
                                            {/* <CiCircleMinus color="#2C4A8B" onClick={() => agentOnbaording('',0,1)} /> */}
                                        </span>
                                    </div>
                                </div>
                            </div>)}
                        {ordersSummary.map((val: any, index: number) => (
                            <div className="d-flex align-items-center py-1 ps-2 " key={index}>
                                <div className="flex-grow-1">
                                    <span className="summary__card--text2"> {val.qty}</span>
                                    <span className="summary__card--text2"> {val.name}</span>
                                    <p> </p>
                                </div>
                                <div className=" text-end summary__card--text">
                                    <div className="d-flex align-items-center">
                                        <span className="mt-1"> ₦{new Intl.NumberFormat().format(val.price)}</span>
                                        <span className="mx-1" />
                                        <span className="">
                                            <CiCircleMinus color="#2C4A8B" onClick={() => handleDeleteOrderSummary(val.name, val.price)} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {Number(planDetails?.discount) === 0 && (<div className="row border-top border-1">
                            {/* <hr /> */}
                            <div className="d-flex flex-row align-items-center p-2 px-3">
                                <div className="flex-grow-1  pt-2 mt-3 pb-3">
                                    <label htmlFor="coupon" className="summary__card--label">
                                        Coupon
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control summary__card--input shadow-none"
                                        onChange={handleCoupon}
                                        // value={couponDiscount}
                                        placeholder="Enter coupon code"
                                    />
                                </div>
                                <div className="mt-3 pt-3 px-2">
                                    <button className=" summary__card--apply ms-2 align-items-center d-flex" onClick={applyCoupon}>
                                        <span>Apply</span>
                                    </button>
                                </div>
                            </div>
                        </div>)}
                        <div className="row mt-3 ps-2 pe-4">
                            <hr className="border-1" />
                            <div className="col-6 summary__card--text2 mb-4 pt-3">
                                <span>Subtotal</span>
                            </div>
                            <div className="col-6 text-end summary__card--text mb-4 pt-3">₦{format(subAmount)} </div>
                            {Number(planDetails?.discount) == 0 && (<><div className="col-6 summary__card--text2 pb-3">
                                <span>Coupon Discount</span>
                            </div>
                                <div className="col-6 text-end summary__card--text pb-3">₦{format(couponDiscount)}</div> </>)}
                            {Number(planDetails?.discount) > 0 && (<><div className="col-6 summary__card--text2 pb-3">
                                <span>Discount</span>
                            </div>
                                <div className="col-6 text-end summary__card--text pb-3">₦{plan_duration=='yearly' ? format((Number(planDetails?.discount) * planPrice) / 100) : 0}</div></>)}
                            <div className="col-6 summary__card--text2 pb-3">
                                <span>Order Total</span>
                            </div>
                            <div className="col-6 text-end summary__card--text pb-3">₦{plan_duration=='yearly' ? format(Number(subAmount) - Number(planDetails?.discount) * planPrice / 100) : format(Number(subAmount))}</div>
                            <div className="col-6 summary__card--text2 pb-3">
                                <span>Payment charges</span>
                            </div>
                            <div className="col-6 text-end summary__card--text pb-3">₦{format(calculateCharges(finalTotals))}</div>
                        </div>
                    </div>
                    <div className="card-footer border-1 mt-3 d-flex bg-transparent pt-4 mb-4 me-3 pe-4 ps-4">
                        <div className="flex-grow-1 summary__card--text2">Total</div>
                        <div className=" text-end">
                            <b>₦{format(finalTotals + calculateCharges(finalTotals))}</b>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 pe-5 d-none d-md-block pb-3">
                    <h5 className="summary__card--subtitle pt-2">Choose onboarding option</h5>
                    <span>You can choose to get expert assistance from a Klakpad agent for setup and training</span>
                </div>
                {
                    onboardingData?.map((item: any, index: number) =>
                        // <>
                        <div key={index} onClick={() => {
                            agentOnbaording(item.description, item.amount, item.id)
                        }} className={`card p-3 w-100 ${isOnboarding == item.description ? 'summary__addon--selectedcard' : 'summary__addon--unselectedcard'} mb-3`}>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <input
                                        className=""
                                        type="radio"
                                        name="onb"
                                        id="onb"
                                        readOnly
                                        // onChange={() => agentOnbaording(item.description, item.amount, item.id)}
                                        // onChange={(e: any) =>  setOnboarding(false)}
                                        checked={isOnboarding == item.description ? true : false}
                                    />
                                    <span style={{ cursor: 'pointer', color: '#111A24', paddingLeft: 10 }}>{item.description}</span>
                                </div>
                                <div
                                    style={{ color: '#60666D' }}
                                    className=" text-end"
                                >
                                    ₦{format(item.amount, 2)}
                                </div>
                            </div>
                        </div>
                        /* <div onClick={() => {
                            agentOnbaording()
                        }} className={`card p-3 w-100 ${isOnboarding == 'agent' ? 'summary__addon--selectedcard' : 'summary__addon--unselectedcard'} mt-3`}>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <input
                                        className=""
                                        type="radio"
                                        name="onb"
                                        id="onb"
                                        // onChange={(e: any) =>  setOnboarding(true)}
                                        checked={isOnboarding == 'agent' ? true : false}
                                    />
                                    <span style={{ cursor: 'pointer', color:'#111A24', paddingLeft: 10 }}>Agent-assisted onboarding</span>
                                </div>
                                <div
                                    style={{ color:'#60666D' }}
                                    className=" text-end"
                                >
                                    ₦{format(agent,2)}
                                </div>
                            </div>
                        </div>  
                        </> */
                    )}
                {
                    finalTotals > 0 &&

                    <div className="col-md-12 pe-5 d-none d-md-block pt-5">
                        <h5 className="summary__card--subtitle pt-2">Payment options</h5>
                    </div>
                }

                <div className="row mt-3 ">
                    {
                        finalTotals > 0 &&
                        paymentGateway.map(({ id, name, selected, description, icon }: any) => (
                            <div className="col-md-6  pb-4" onClick={(e: any) => handlePaymentType(e, id)} key={id}>
                                <div
                                    className={`card p-3 w-100 summary__addon--unselectedcard ${selected === id ? 'summary__addon--selectedcard' : ''}`}
                                    onClick={() => {
                                        handlePayment(name)
                                    }}
                                >
                                    <PlanpaymentType
                                        formattedAmount={formattedAmount}
                                        name={name}
                                        id={id}
                                        handlePaymentType={handlePaymentType}
                                        selected={selected}
                                        handlePayment={handlePayment}
                                        description={description}
                                        plan_id={plan_id}
                                        plan_duration={plan_duration}
                                        planDetails={planDetails}
                                        paymentMethodChoices={paymentMethodChoices}
                                        handleRadioChange={handleRadioChange}
                                        plansPayment={paymentGateway}
                                    />
                                </div>
                            </div>
                        ))}

                    {finalTotals > 0 && payment_method === 'card' ? (
                        <div className="col-md-12 mt-3 pb-3">
                            <div className="card ">
                                {paymentMethodChoices &&
                                    paymentMethodChoices.map((val: any, index: number) => (
                                        <div
                                            className="d-flex flex-row align-items-center summary__card--payment-options"
                                            style={{ cursor: 'pointer' }}
                                            key={val.id}
                                            onClick={(e: any) => {
                                                // handleRadioChange(e, val.id, 'saved_card')
                                                dispatch(updatePlanDetailsStates({ auth_code: val.authorization_code, card_type: 'save_card' }))
                                            }}
                                        >
                                            <CardOptions handleRadioChange={handleRadioChange} val={val} name="tops" />
                                        </div>
                                    ))}
                                <div className="d-flex flex-row mt-3 summary--text">
                                    <div
                                        style={{ cursor: 'pointer', color: '#2C4A8B' }}
                                        className="col-10 d-flex mb-3 ps-3"
                                        onClick={(e: any) => handleRadioChange(e, 3, 'new_card')}
                                    >
                                        Pay with new card
                                    </div>
                                    <div className="col-2 text-center">
                                        <div className="form-check">
                                            <input
                                                className=""
                                                type="radio"
                                                name="tops"
                                                id="tops"
                                                onChange={(e: any) => handleRadioChange(e, 3, 'new_card')}
                                                checked={card_type === 'new_card' ? true : false}
                                            />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {finalTotals > 0 && paymentMethod === 'card' ? (
                        <>
                            {card_type === "new_card" &&
                                <div className="row mt-3 ">
                                    <span>
                                        <input
                                            onClick={() => {
                                                dispatch(updatePlanDetailsStates({ saved_card: saved_card === 1 ? 0 : 1 }))
                                            }}
                                            type="checkbox"
                                            name=""
                                            id=""
                                        />{' '}
                                        <span className="mx-1 summary__card--smalltext">Save card information</span>
                                    </span>
                                </div>}
                            {/* <div className="row mt-3">
                                <span>
                                    <input type="checkbox" name="" id="" />{' '}
                                    <span className="mx-1 summary__card--smalltext">Enable autorenew of subscription</span>
                                </span>
                            </div> */}
                        </>
                    ) : (
                        ''
                    )}

                    <div className="col-12 mt-5">
                        {/* {!validation ? (
                            <button
                                className="w-100 py-2 summary__card--btn"
                                onClick={
                                    finalTotals > 0 ?
                                        paymentMethod === 'card' ?
                                            validateCardPayment :
                                            paymentMethod === 'wallet' ?
                                                handlePaymentProceed :
                                                () => alert("You have to pick a payment method") :
                                        () => subscribeFree()
                                }
                                data-bs-target={`${payment_method === 'wallet' ? '#exampleModal' : ''}`}
                                data-bs-toggle={`${payment_method === 'wallet' ? 'modal' : ''}`}
                            >
                                {finalTotals > 0 ? payment_method === 'wallet' ? 'Proceed to payment' : 'Validate payment' : 'Subscribe for free'} <FaArrowRight className="mx-2" />
                            </button>
                        ) : ( */}
                            <PaystackButton className="w-100 py-2 summary__card--btn" {...componentProps} />
                        {/* )} */}
                    </div>
                </div>


            </div>
            {isModalVisible && <PayWithWallet />}
        </>
    )
}
export default SummaryForm
