import React from 'react';

const SuccessResponse: React.FC = () => {
    return (
        <div className=" success__card bg-white ">
        <div className="card-body text-center">
            <svg width="74" height="73" viewBox="0 0 74 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="37" cy="36.5" r="36.5" fill="#18BA5C" />
                <path d="M52.1654 26L31.9987 46.1667L22.832 37" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <h5 className="card-title success__card--title">Subsciption successful!</h5>
            <p className="card-text success__card--description">Your plus plan subscription has been successfully activated</p>
        </div>
    </div>
    );
};

export default SuccessResponse;

export const ErrorResponse = () => {
    return (
        <div className="success__card bg-white">
            <div className="card-body text-center">
                <svg width="74" height="73" viewBox="0 0 74 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="37" cy="36.5" r="36.5" fill="#E74C3C" />
                    <path d="M52.1654 26L31.9987 46.1667L22.832 37" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <h5 className="card-title success__card--title">Subscription not successful!</h5>
                <p className="card-text success__card--description">There was an issue with your payment. Please try again.</p>
            </div>
        </div>
    );
};