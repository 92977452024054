import { useState, useEffect, useRef, useMemo } from 'react';
import { AddOn, PlanDetails, SelectedAddOn } from '../../../utility/subscriptionInterfaces';
import { useQuery } from 'react-query';
import { getRequest } from '../../../resources/api-request';
import { useLocation, useParams } from 'react-router-dom';
import { postRequest } from '../../../utility/apiRequest';
import axios from 'axios';

 
      type durationType = 'Yearly' | 'Monthly'
const usePlanDetails = () => {
   
    const [selectedAddOns, setSelectedAddOns] = useState<SelectedAddOn[]>([]);
    const [addOns, setAddOns] = useState<AddOn[]>([])
    const [duration, setDuration] = useState<durationType>('Yearly');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [balance, setBalance] = useState<number>(0)
    const [displayAddOnsOrSummary, setDisplayAddOnsOrSummary] = useState<boolean>(true);
    const [subscriptionResponse, setSubscriptionResponse] = useState<boolean>(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const locationId = params.get('locationId')
    const agentCode = params.get('agentCode')
    const status = params.get('status')
    const [planDetails, setPlanDetails] = useState<PlanDetails[]>([]) 
    const [error, setError] = useState(false)
     const [loading, setLoading] = useState(false)

    const { subtotal, discount, total } = useMemo(() => {
      // Ensure planDetails is available; otherwise, default to 0
      const basePrice =
        planDetails.length > 0
          ? duration === 'Yearly'
            ? planDetails[0].totalAmount  // Using totalAmount for Yearly
            : planDetails[0].amountMonth   // Using amountMonth for Monthly
          : 0;
    
      // Sum up the prices from selected add-ons (if any)
      const addOnsTotal = selectedAddOns.reduce((sum, addOn) => sum + addOn.price, 0);
    
      // Calculate the subtotal as the sum of the base price and add-ons total
      const calculatedSubtotal = basePrice + addOnsTotal;
    
      // Only apply discount when duration is Yearly; otherwise discount is 0.
      const calculatedDiscount =
        duration === 'Yearly' && planDetails.length > 0
          ? planDetails[0].DiscountAmount || 0
          : 0;
    
      // Total is the subtotal minus the discount.
      const calculatedTotal = calculatedSubtotal - calculatedDiscount;
    
      return {
        subtotal: calculatedSubtotal,
        discount: calculatedDiscount,
        total: calculatedTotal,
      };
    }, [selectedAddOns, duration, planDetails, subscriptionResponse]);
    
    
   const { isLoading, isError, data } = useQuery<any>('result', () => getRequest(`reseller/subscription-summary-agent/${locationId}`))
 
    const getBalance = useQuery<any>('respond', ()=> getRequest(`https://testing.reseller.klakpad.dev/agent/balance?agentCode=${agentCode}`))

  
    const handleSelect = (selected: SelectedAddOn) => {
        setSelectedAddOns((prev) => {
          const existingIndex = prev.findIndex((item) => item.id === selected.id);
          if (existingIndex !== -1) {
            return prev.map((item, index) => (index === existingIndex ? selected : item));
          }
          return [...prev, selected];
        });
      };
      
      const handleRemove = (id: string) => {
        setSelectedAddOns((prev) => prev.filter((item) => item.id !== id));
      };
   

const toggleDropdown = () => {
  setIsOpen((prev) => !prev);  
};

  const handleDurationDropdown = (period: durationType) => {
    setDuration(period);
    setIsOpen(false);
  };
  const toggleDisplayAddOnsOrSummary = () => {
    setDisplayAddOnsOrSummary(!displayAddOnsOrSummary);
  }
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
   
  useEffect(() => {
    console.log({subscriptionResponse});
  }, [subscriptionResponse, setSubscriptionResponse]);
  useEffect(() => {
    if (!isLoading && data) {
      // Get the raw addOns array from your data
      const rawAddOns = data?.data?.data[0]?.addons || [];
        setPlanDetails(data?.data?.data[0]?.planDetails)
      // Transform each addOn to include a unique id.
      // Here we use the add-on's name (assuming it's unique) or a fallback using the index.
      const addonsWithId = rawAddOns.map((addon: any, index: number) => ({
        ...addon,
        id: addon.id || addon.name || `addon-${index}`,
      }));
  
      setAddOns(addonsWithId);
      
    }
  }, [isLoading, data]);
 
  useEffect(() => {
    if (planDetails && planDetails[0]?.walletBalance) {
      setBalance(planDetails[0]?.walletBalance);
    }
  }, [planDetails]);

  useEffect(() => {
    if (!getBalance.isLoading && getBalance.data) {
      setBalance(getBalance.data?.data?.balance)
    }
  },[getBalance])

  useEffect(() => {
    console.log("Global duration changed to:", duration);
  }, [duration]);

 

  const save = () => {
    const res = postRequest('reseller/subscribe-with-token')
  }
  
    return {
       handleSelect,
         handleRemove,
         addOns,
         selectedAddOns,
         setSelectedAddOns,
         setDuration,
        duration,
        handleDurationDropdown,
        toggleDropdown,
        dropdownRef,
        isOpen,
        displayAddOnsOrSummary,
        toggleDisplayAddOnsOrSummary,
        total,
        subtotal,
        discount,
        balance,
        subscriptionResponse,
        setSubscriptionResponse,
        planDetails,
        locationId,
        agentCode,
        error,
        setError,
        setLoading,
        loading
    };
};

export default usePlanDetails;