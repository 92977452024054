import React, { useEffect, useState } from 'react';
import usePlanDetails from './hook/usePlanDetails';
import Modal from '../../components/modals/customModal';
import AddMoneyModalContent from './payWithToken';
import SuccessResponse, { ErrorResponse } from './successResponse';
import { Loader } from '../../components/loader';
import DesktopSubscription from './desktopSubscription';
import MobileSubscription from './mobileSubscription';

const AgentPlanDetails = () => {
  const {
    addOns,
    handleSelect,
    handleRemove,
    selectedAddOns,
    displayAddOnsOrSummary,
    toggleDisplayAddOnsOrSummary,
    subtotal,
    discount,
    dropdownRef,
    total,
    duration,
    isOpen,
    subscriptionResponse,
    setSubscriptionResponse,
    error,
    setError,
    setLoading,
    loading,
  } = usePlanDetails();

  const [isAddMoneyModalOpen, setIsAddMoneyModalOpen] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const openAddMoneyModal = () => setIsAddMoneyModalOpen(true);
  const closeAddMoneyModal = () => setIsAddMoneyModalOpen(false);

  useEffect(() => {
    if (subscriptionResponse) {
      setIsModalVisible(true);
      const timer = setTimeout(() => {
        setIsModalVisible(false);
        const newAppUrl = `${process.env.REACT_APP_RESELLAR_URL}/dashboard`;
        window.location.href = newAppUrl;
      }, 10000); // 10 seconds in milliseconds
      window.close();
      return () => clearTimeout(timer);
    }
  }, [subscriptionResponse]);

  useEffect(() => {
    if (error) {
      setIsErrorVisible(true);
      const timer = setTimeout(() => {
        setIsErrorVisible(false);
        // const newAppUrl = `${process.env.REACT_APP_RESELLAR_URL}/dashboard`;
        // window.location.href = newAppUrl;
      }, 10000); // 10 seconds in milliseconds
        //  window.close();
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <>
      <div className="subscription">
        <div className="container">
          {/* Mobile Layout */}
          <MobileSubscription
            addOns={addOns}
            selectedAddOns={selectedAddOns}
            handleSelect={handleSelect}
            handleRemove={handleRemove}
            displayAddOnsOrSummary={displayAddOnsOrSummary}
            toggleDisplayAddOnsOrSummary={toggleDisplayAddOnsOrSummary}
            subtotal={subtotal}
            discount={discount}
            total={total}
            duration={duration}
            isOpen={isOpen}
            dropdownRef={dropdownRef}
            openAddMoneyModal={openAddMoneyModal}
          />

          {/* Desktop Layout */}
          <DesktopSubscription
            addOns={addOns}
            selectedAddOns={selectedAddOns}
            handleSelect={handleSelect}
            handleRemove={handleRemove}
            subtotal={subtotal}
            discount={discount}
            total={total}
            duration={duration}
            isOpen={isOpen}
            dropdownRef={dropdownRef}
            openAddMoneyModal={openAddMoneyModal}
          />
        </div>
      </div>

      {/* Conditionally render Loader */}
      {loading && <Loader />}

      <Modal
        id="addMoneyModal"
        title="Pay with Token"
        description="You’ll use your Klakpad token wallet to complete this payment."
        show={isAddMoneyModalOpen}
        onClose={closeAddMoneyModal}
      >
        <AddMoneyModalContent
          onClose={closeAddMoneyModal}
          total={total}
          subscriptionResponse={subscriptionResponse}
          setSubscriptionResponse={setSubscriptionResponse}
          error={error}
          setError={setError}
          setLoading={setLoading}
          loading={loading}
        />
      </Modal>

      <div
        className={`modal fade ${isModalVisible ? 'show' : ''}`}
        style={{ display: isModalVisible ? 'block' : 'none' }}
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog border-0 bg-transparent" role="document">
          <div className="modal-content border-0 bg-transparent">
            <div className="modal-body border-0">
              <SuccessResponse />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade ${isErrorVisible ? 'show' : ''}`}
        style={{ display: isErrorVisible ? 'block' : 'none' }}
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog border-0 bg-transparent" role="document">
          <div className="modal-content border-0 bg-transparent">
            <div className="modal-body border-0">
              <ErrorResponse />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentPlanDetails;
