import React from 'react';
import { AddOn, SelectedAddOn } from '../../utility/subscriptionInterfaces';
import { usersSvg, productSvg, outletSvg } from '../../resources/iconSVGs';

export const AddOnCard: React.FC<{
    addOn: AddOn;
    selected: SelectedAddOn | null;
    onSelect: (selected: SelectedAddOn) => void;
}> = ({ addOn, selected, onSelect }) => {
    
    return (
        <>
            
            <div className="p-0 pb-0 border-top m-0">

                <div className="d-flex subscription__addExtras--section" >
                    <div className="pe-3">
                        {addOn.name.includes('User') && outletSvg}
                        {addOn.name.includes('Product') && usersSvg}
                        {addOn.name.includes('Outlet') && productSvg}
                    </div>
                    <div className="">
                        <h3 className="subscription__addExtras--subtitle">{addOn.name}</h3>
                        <p className="text-gray-500 subscription__addExtras--description">{addOn.description}</p>
                    </div>
                </div>
                <div className="mt-2 p-2  grid ">
                    {addOn.options.map(option => (
                        <label
                            key={option.qty}
                            className={`g-col-4 subscription__addExtras--card cursor-pointer justify-content-between ${
                                selected && selected.id === addOn.id && selected.qty === option.qty 
                                  ? 'subscription__addExtras--selectedCard'
                                  : ''
                              }`}
                        
                        >
                            <div className="d-flex ">
                                <div className="">
                                    <input
                                        type="radio"
                                        name={addOn.id}
                                        value={option.qty}
                                        checked={selected?.qty === option.qty}
                                        onChange={() => onSelect({ id: addOn.id, name: addOn.name, qty: option.qty, price: option.amount })}
                                        className="mr-2 subscription__addExtras--card-input"
                                    />
                                </div>
                                <div className="">
                                    <div className="subscription__addExtras--card-qty">{option.qty.toLocaleString()} </div>
                                    <div className="subscription__addExtras--card-amount">₦{option.amount.toLocaleString()}.00</div>
                                </div>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
};
