import React, { useState } from 'react';
import usePlanDetails from './hook/usePlanDetails';
import { postRequest } from '../../utility/apiRequest';
import { Loader } from '../../components/loader';
import { setError } from '../../store/reducers/todoSlice';


interface AddMoneyModalContentProps {
    onClose: () => void;
    total: number;
    subscriptionResponse: boolean;
    setSubscriptionResponse: (value: boolean) => void;
    error: boolean;
    setError: (value: boolean) => void;
    loading:boolean;
    setLoading:  (value: boolean) => void;
}

const AddMoneyModalContent: React.FC<AddMoneyModalContentProps> = ({ onClose, total, setSubscriptionResponse, error, setError,  setLoading }) => {
    const { balance, selectedAddOns, planDetails, duration, locationId, agentCode, } = usePlanDetails();
  

    const handleAddMoney = () => {
        setLoading(true);
        if (total > balance) {
          alert("Insufficient Balance");
          onClose();
          return;
        }
      
        // Extract the quantities from the selected add-ons.
        const additionalProducts = selectedAddOns.find(
          (addOn) => addOn.id === 'product' || addOn.name.toLowerCase().includes('product')
        )?.qty || 0;
      
        const additionalUsers = selectedAddOns.find(
          (addOn) => addOn.id === 'user' || addOn.name.toLowerCase().includes('user')
        )?.qty || 0;
      
        const additionalWarehouse = selectedAddOns.find(
          (addOn) => addOn.id === 'warehouse' || addOn.name.toLowerCase().includes('outlet')
        )?.qty || 0;
      
        // Construct your data object. Adjust the properties as needed.
        const data = {
          plan_id: planDetails[0]?.plan_id,  // Make sure planDetails is loaded.
          plan_amount: duration === 'Yearly' ? planDetails[0]?.totalAmount : planDetails[0]?.amountMonth,
          total_amount: duration === 'Yearly' ? planDetails[0]?.totalAmount : planDetails[0]?.amountMonth, 
          discount_percentage: duration === 'Yearly' ? planDetails[0]?.resellerDiscount || 0 : 0,
          amount_paid: total,
          coupon: "",
          payment_method: "wallet",
          duration: duration === 'Yearly' ? 12 : 1, // Or however you want to represent duration.
          location_id: locationId,
          additional_products: additionalProducts,
          additional_users: additionalUsers,
          additional_warehouse: additionalWarehouse,
          onboarding: 0,
          agent_id: agentCode
        };
      
       
      
        // Call your API.
        postRequest('reseller/subscribe-with-token', data )
        .then((response) => {
            console.log("Subscription response", response.data);
            if(response.data.success === true){
                setSubscriptionResponse(true) 
            }
           if (response.data.success === false) {
                setError(true)
           }
          })
          .catch((error) => {
            console.error("Error subscribing:", error);
          })
          .finally(() => {
            setLoading(false)
            onClose();
          });
      };
      

    const handleClose = () => {
        onClose();
    };

    return (
        <>

         
            <div className="token">
                <div className="d-flex flex-column ">
                    <span className="token--span">Token Balance: </span>
                    <div className="">
                        {' '}
                        <span className="token--balance">₦{balance} </span>
                        <span className="token--equivalent"> KPT ≈ {balance.toLocaleString()} </span>
                    </div>
                </div>
                <div className="row" style={{
                    marginTop: 16,
                    padding: '0 10px'
                }}>
                    {total > balance ? (
                        <div className="alert  p-0 text-center py-2" style={{
                            border: '1px solid #F3949B',
                            color: '#C90000',
                            fontSize: 12,
                            fontWeight: 400,
                            letterSpacing:-.12,
                            height: 36,
                            borderRadius: 8,
                            backgroundColor: '#FDF3F4'
                        }}>
                            <svg className='me-3' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8" cy="8" r="7.25" stroke="#D52B37" strokeWidth="1.5" />
                                <g clipPath="url(#clip0_11598_32474)">
                                    <path
                                        d="M8.13672 7.2002C7.93781 7.2002 7.74704 7.27921 7.60639 7.41987C7.46574 7.56052 7.38672 7.75128 7.38672 7.9502V12.4502C7.38672 12.6491 7.46574 12.8399 7.60639 12.9805C7.74704 13.1212 7.93781 13.2002 8.13672 13.2002C8.33563 13.2002 8.5264 13.1212 8.66705 12.9805C8.8077 12.8399 8.88672 12.6491 8.88672 12.4502V7.9502C8.88672 7.75128 8.8077 7.56052 8.66705 7.41987C8.5264 7.27921 8.33563 7.2002 8.13672 7.2002ZM8.13672 4.2002C7.9513 4.2002 7.77004 4.25518 7.61587 4.35819C7.4617 4.46121 7.34154 4.60762 7.27058 4.77893C7.19963 4.95024 7.18106 5.13874 7.21723 5.32059C7.25341 5.50245 7.34269 5.6695 7.47381 5.80061C7.60492 5.93172 7.77196 6.02101 7.95382 6.05718C8.13568 6.09336 8.32418 6.07479 8.49549 6.00383C8.66679 5.93287 8.81321 5.81271 8.91622 5.65854C9.01924 5.50437 9.07422 5.32312 9.07422 5.1377C9.07422 4.88905 8.97545 4.6506 8.79963 4.47478C8.62382 4.29897 8.38536 4.2002 8.13672 4.2002Z"
                                        fill="#D52B37"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_11598_32474">
                                        <rect width="9.6" height="9.6" fill="white" transform="translate(3.19922 3.2002)" />
                                    </clipPath>
                                </defs>
                            </svg>
                            Insufficent balance. Please buy more tokens
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3 border-top token__payment">
                    <span className="token__payment--total">Total</span>
                    <span className="token__payment--token">{total.toLocaleString()} KPT</span>
                </div>
                
                <div className="modal-footer border-0 m-0 p-0">
                    <button type="button" className="customModal--paymentButton" onClick={handleAddMoney}>
                        Add Money
                    </button>
                </div>
            </div>
          
        </>
    );
};

export default AddMoneyModalContent;
