import React from "react";


interface PaymentSectionProps {
  children?: React.ReactNode;
  onProceed: () => void;
  onToggle: () => void;
}

const PaymentSection: React.FC<PaymentSectionProps> = ({ children, onProceed, onToggle }) => (
  <div>
    {children}
    <div className="mt-5">
      <button className="subscription__plansDetails--payment" onClick={onProceed}>
        Proceed to Payment
      </button>
    </div>
    <div className="">
      <button onClick={onToggle} className="subscription__plansDetails--button">
        Toggle Extras
      </button>
    </div>
  </div>
);

export default PaymentSection;
