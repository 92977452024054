import React from 'react';
import OrderSummary from './orderSummary';
import { AddOnCard } from './addOnCard';
import PaymentSection from './paymentSectionButtons';


interface DesktopSubscriptionProps {
  addOns: any[];
  selectedAddOns: any[];
  handleSelect: (addOn: any) => void;
  handleRemove: (addOn: any) => void;
  subtotal: number;
  discount: number;
  total: number;
  duration: "Yearly" | "Monthly";
  isOpen: boolean;
  dropdownRef: React.RefObject<any>;
  openAddMoneyModal: () => void;
}

const DesktopSubscription: React.FC<DesktopSubscriptionProps> = ({
  addOns,
  selectedAddOns,
  handleSelect,
  handleRemove,
  subtotal,
  discount,
  total,
  duration,
  isOpen,
  dropdownRef,
  openAddMoneyModal,
}) => {
  return (
    <div className="row">
      <div className="col-md-6 d-md-block d-none pe-5 border-end">
        <OrderSummary
          selectedAddOns={selectedAddOns}
          handleRemove={handleRemove}
          subtotal={subtotal}
          discount={discount}
          total={total}
          isOpen={isOpen}
          dropdownRef={dropdownRef}
          duration={duration}
        />
        <PaymentSection onProceed={openAddMoneyModal} onToggle={() => { /* optional toggle if needed */ }} />
      </div>
      <div className="col-md-6 d-md-block d-none ps-2">
        <div className="subscription__addExtras">
          <h3 className="subscription__addExtras--title">Add Extras</h3>
          <p className="subscription__addExtras--description">
            Enhance your plan with additional features to propel your company forward.
          </p>
        </div>
        <div className="grid gap-4">
          {addOns.map((addOn) => (
            <AddOnCard
              key={addOn.id}
              addOn={addOn}
              selected={selectedAddOns.find((item) => item.id === addOn.id) || null}
              onSelect={handleSelect}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DesktopSubscription;
