// src/components/Modal.tsx
import React, { useEffect, useRef } from 'react';

interface ModalProps {
    id: string; // Unique identifier for the modal
    title?: string;
    description?: string;
    show: boolean; // Control modal visibility
    onClose: () => void; // Callback when modal is closed
    children: React.ReactNode; // Modal content
    modalWidth?: string;
}

const Modal: React.FC<ModalProps> = ({ id, title, description, show, onClose, children, modalWidth }) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const bsModalRef = useRef<bootstrap.Modal | null>(null);

    useEffect(() => {
        if (modalRef.current) {
            bsModalRef.current = new bootstrap.Modal(modalRef.current, {
                backdrop: 'static', // Prevent closing by clicking outside
                keyboard: true, // Allow closing with Esc key
                focus: false 
            });

            // Event listener for when the modal is hidden
            const handleHidden = () => {
                onClose();
            };

            modalRef.current.addEventListener('hidden.bs.modal', handleHidden);

            return () => {
                if (modalRef.current) {
                    modalRef.current.removeEventListener('hidden.bs.modal', handleHidden);
                }
                if (bsModalRef.current) {
                    bsModalRef.current.dispose();
                }
            };
        }
    }, [onClose]);

    useEffect(() => {
        if (bsModalRef.current) {
            if (show) {
                bsModalRef.current.show();
            } else {
                bsModalRef.current.hide();
            }
        }
    }, [show]);

    return (
        <div className="modal fade customModal" id={id} tabIndex={-1} aria-labelledby={`${id}Label`} aria-hidden="true" ref={modalRef}  style={show ? { display: 'block' } : { display: 'none' }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center mx-auto px-2" style={{ width: modalWidth ? modalWidth : '' }}>
                <div className="modal-content p-3 border-0">
                    <div className="modal-header border-0 d-flex justify-content-between align-items-center">
                        {title && (
                            <div>
                                {' '}
                                <h5 className="modal-title customModal--title" id={`${id}Label`}>
                                    {title}
                                </h5>
                                <p className="customModal--description">{description}</p>
                            </div>
                        )}
                        <button type="button" className="customModal--close text-dark" data-bs-dismiss="modal" aria-label="Close">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 1L1 13M1 1L13 13" stroke="#1A1F25" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body">{children}</div>
                    {/* Optional Footer */}
                </div>
            </div>
        </div>
    );
};

export default Modal;
